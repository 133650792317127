import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.section``

const People = ({
  data: {
    acf: { people },
  },
}) => {
  return (
    <Container>
      <h2 className="is-vhidden">People</h2>

      <Grid>
        {people.map((person, i) => (
          <Row
            key={i}
            pushY={i !== 0 && 2}
            pullY={i === people.length - 1 && 2}
            mq={{
              xsmallDown: {
                pushY: i !== 0 && 5,
                pullY: i === people.length - 1 && 6,
              },
            }}
          >
            <Cell
              size={7}
              mq={{ mediumDown: { size: 11 }, xsmallDown: { size: 23 } }}
            >
              {person.image && person.image.localFile && (
                <Img
                  fluid={person.image.localFile.childImageSharp.fluid}
                  alt={person.name}
                />
              )}
            </Cell>

            <Cell
              size={10}
              pushX={5}
              mq={{
                mediumDown: { pushX: 2 },
                xsmallDown: { size: 23, pushX: 0 },
              }}
            >
              <Row>
                <Cell
                  size={23}
                  mq={{
                    xsmallDown: { pushY: 1.75 },
                  }}
                >
                  <h3 className="styled-p-leading">
                    {person.name}
                    <br />
                    {person.title}
                  </h3>
                </Cell>

                <Cell
                  size={23}
                  pushY={1}
                  mq={{
                    xsmallDown: { pushY: 1.75 },
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: person.text }}
                    className="styled"
                  />
                </Cell>
              </Row>
            </Cell>
          </Row>
        ))}
      </Grid>
    </Container>
  )
}

People.propTypes = {
  data: PropTypes.object.isRequired,
}

export default People
