import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import Intro from "../components/intro"
import Blurbs from "../components/blurbs"
import Accordion from "../components/accordion"
import People from "../components/people"

const PeoplePage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    intro,
    people,
    blurbs,
    accordion,
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      <Intro data={intro} />

      <People data={people} />

      <Blurbs data={blurbs} />

      <Accordion data={accordion} />
    </Layout>
  )
}

export default PeoplePage

PeoplePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    ...HeroPageFragment
    ...IntroPageFragment
    ...PeoplePageFragment
    ...BlurbsPageFragment
    ...AccordionPageFragment
  }
`
